<template>
  <div class="s-layout s-flex-direction--column">
    <headerSimple inverted="false" />
    <main class="s-expand s-padding-top--s s-padding-bottom--xl">
      <section>
        <div class="s-wrapper pageText">
          <router-view/>
        </div>
      </section>
    </main>
    <footerMain/>
  </div>
</template>
<script>
import headerSimple from '@/components/headerSimple.vue'
import footerMain from '@/components/footerMain.vue'

export default {
  components: {
    headerSimple,
    footerMain
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
