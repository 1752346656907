<template lang="pug">
header.simple.s-header.s-swatch-black(:class='{inverted}')
  .s-headerBar
    .s-wrapper
      .s-relative.s-flex-items--center
        .s-expand
          a.s-headerLogo.s-padding-y--s.s-margin-right--l(href='/')
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path class="st0" d="M14.5 22.1H7.9c-.8 0-1.4-.1-1.8-.3-.4-.2-.6-.8-.6-1.7v-1.5c0-.8.1-1.4.4-1.7.3-.3.9-.5 1.9-.5h11.9v-3.7c-1-.4-2.8-.6-5.4-.8-2.7-.2-5.2-.3-7.7-.3-4.4 0-6.6 2.2-6.6 6.6v2.2c0 2.4.6 4.1 1.7 5.1S4.6 27 6.9 27h6.5c.8 0 1.3.2 1.6.5.3.3.5.8.5 1.5v1.6c0 1.3-.7 2-2.1 2H1.1v3.6c.8.4 2.7.6 5.7.9s5.7.3 8.1.3c4.1 0 6.2-2.2 6.2-6.5v-2.5c0-1.9-.5-3.5-1.6-4.6-1.2-1.1-2.8-1.7-5-1.7zM38.2 11.6c-1.4 0-2.9.3-4.6 1-1.6.6-2.9 1.3-3.8 2L28.7 12h-4.4v38.3h5.5V35.9c1.2.5 2.6.9 4.2 1.2 1.6.3 3 .5 4.1.5 2.6 0 4.6-.9 6-2.6 1.6-2 2.3-5.5 2.3-10.6.1-8.5-2.7-12.8-8.2-12.8zm1 20.8c-.5.4-1.2.6-2.1.6-2.6 0-5-.4-7.2-1.1V18.3c2.3-1.2 4.7-1.8 7.2-1.8 1.3 0 2.2.6 2.9 1.9.6 1.3 1 3.3 1 6.2 0 4.3-.6 6.9-1.8 7.8zM71.2 20.8c0-2.8-.4-4.9-1.2-6.1-.4-.7-.9-1.2-1.4-1.6s-1.2-.7-2.1-.9c-.9-.2-1.7-.3-2.6-.4-.8-.1-2.2-.1-4-.1-1.8 0-3.4.2-4.9.6-1.4.4-2.6 1.3-3.6 2.5-1.2 1.7-1.9 4.9-1.9 9.7 0 4.8.6 8.2 1.8 10.1 1.2 1.9 3.4 2.9 6.6 2.9 5.8 0 10-.4 12.6-1.3v-3.9c-2.2.4-4.7.5-7.6.5s-4.5-.1-5.1-.2-1.1-.4-1.6-.8c-.8-.7-1.2-2.3-1.2-4.8h9c4.9 0 7.2-2.1 7.2-6.2zm-15.9 1.9c0-1.1 0-2.1.1-3 .1-1 .5-1.8 1.2-2.6.5-.6 2-.9 4.5-.9 2 0 3.2.3 3.8.8.5.5.8 1.7.8 3.6 0 1.4-.8 2.1-2.4 2.1h-8zM80.6 31.3c-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5v-3.5c-.2 0-1.4-.3-3.6-1-.8-.2-1.5-.7-1.8-1.4zM96.4 32.7c-.8-.3-1.4-.7-1.8-1.4-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5.1v-3.5c-.3 0-1.5-.3-3.7-1z"/></svg>
          span.s-headerTitle.s-relative.s-text-size--s--semibold--caps--nowrap.s-padding-left--l(v-show='title')
            | {{ title }}
</template>
<script>
export default {

  props: {
    inverted: { type: Boolean },
    title: { type: String }
  },

  mounted () {
    // New nav
    const sections = [...document.querySelectorAll('.navSection')]
    const getLinkById = (id) => document.querySelector(`a[data-nav='${id}']`)
    const inView = (section) => {
      let top = section.offsetTop
      const height = section.offsetHeight
      const header = document.querySelector('.s-header').offsetHeight

      while (section.offsetParent) {
        section = section.offsetParent
        top += section.offsetTop - header - 100
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        top + height > window.pageYOffset
      )
    }

    function navInit () {
      let next = false
      sections.forEach((section) => {
        const a = getLinkById(section.id)

        if (inView(section) && !next) {
          if (!a.classList.contains('current')) {
            a.classList.add('current')
            section.classList.add('animate')
          }
          next = true
        } else {
          a.classList.remove('current')
          section.classList.remove('animate')
        }
      })
    }
    window.onload = () => {
      navInit()
    }

    // Header
    const header = document.querySelector('.s-header')

    window.addEventListener('scroll', function () {
      // Floater
      if (window.pageYOffset > 20 && window.innerWidth > 1020) {
        header.classList.add('floating')
      } else if (window.pageYOffset > 0 && window.innerWidth < 1020) { // mobile top margin
        header.classList.add('floating')
      } else {
        header.classList.remove('floating')
      }

      // Nav
      navInit()
    })

    window.addEventListener('resize', function () {
      if (window.innerWidth > 1020) {
        header.classList.remove('navMobileVisible')
      }
    })
  },
  methods: {
    hideMobileNav () {
      document.querySelector('.s-header').classList.remove('navMobileVisible')
    },
    toggleBurger () {
      document.querySelector('.s-header').classList.toggle('navMobileVisible')
    }
  },

  watch: {
    $route (to, from) {
      document.querySelector('.s-header').classList.remove('navMobileVisible')
    }
  }

}
</script>
<style lang="less">
  body {
    box-shadow: none !important;
  }
  #app {
    max-width: none;
  }
</style>
